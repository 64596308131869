<template>
<div>
        <loading :active.sync="loading"></loading>
        <div class="row"><div class="col-md-12">
          <b-card style="max-width:860px">
            <div slot="header">
                <CRow>
                <CCol sm="6">
                    <strong>Encargos: {{ p.enc_name }}</strong>

                    <span style="padding: 5px; margin-left:10px; width:70px; margin-top:9px; font-size:14px; font-weight:bold; background-color: yellow;">{{ encargo_total | grana }} %</span>
                </CCol>
                <CCol sm="6" style="text-align:right;">
                    <b-button size="sm" style="color:white;" variant="warning" v-b-modal.modal-encargo>Adicionar encargo...</b-button>
                    &nbsp;&nbsp;
                    <b-button variant="link" size="sm" @click="ajuda()"><CIcon name="cil-lightbulb" />&nbsp;<small>Ajuda</small></b-button>
                </CCol>
                </CRow>
          </div>

        <!--<table class="table">
            <tbody>
                <tr>
                <td colspan="2" style="width:90%">

                <b-form-select size="lg" @change.native="mudarEncargo()" style="height:42px !important; font-size:16px !important;" v-model="encargo" :options="encSelect"></b-form-select>
                    
                </td>
                <td style="width:10%;text-align:right;" class="amarelo">
                    <h4 style="margin-top:9px; font-size:16px;">{{ encargo_total_hora | grana }} %</h4>
                </td>
                <td style="width:10%;text-align:right;" class="amarelo">
                    
                </td>
                </tr>
            </tbody>
        </table>-->

        <table class="table table-hover">
            <thead>
            <tr>
                <th style="width:6%"></th>
                <th style="width:84%">Encargo</th>
                <!--<th style="width:10%">Perc. Horista</th>-->
                <th style="width:10%">Perc. Mensalista</th>
            </tr>
            </thead>

            <tbody v-for="(encargoItem, index) in enc" :key="index" :set="grupoAnterior = (index === 0  ? '' : enc[(index-1)].eit_group)">
                <tr v-if="grupoAnterior != enc[index].eit_group">
                    <td data-title="Grupo" colspan="2"><strong>{{ enc[index].eit_group }}</strong></td>
                    <!--<td></td>-->
                    <td></td>
                </tr>
                <tr>
                    <td data-title="Ativo?">
                      <small>
                       <b-form-checkbox @change.native="editar(index)"
                        v-model="enc[index].eit_active" 
                      ></b-form-checkbox>
                      <a style="position:absolute; left: 45px; margin-top: -18px; z-index:999;" href="javascript:;" @click="removeEsse(index)">❌</a></small>
                    </td>
                    <td data-title="Encargo">
                      <b-form-input class="verde" @blur.native="editar(index)" v-model="enc[index].eit_name"></b-form-input>
                    </td>
                    <!--<td>
                      <vue-autonumeric :options="perc" class="verde form-control direita" @blur.native="editar(index)" v-model="enc[index].eit_valor_hora"></vue-autonumeric>
                    </td>-->
                    <td data-title="Perc. Mensal">
                      <vue-autonumeric :options="perc" class="verde form-control direita" @blur.native="editar(index)" v-model="enc[index].eit_valor_mes"></vue-autonumeric>
                    </td>
                </tr>
            </tbody>

            <tfoot>
                <tr>
                    <th colspan="2">Grupo D - Reincidência de A sobre B</th>
                    <!--<th class="amarelo" style="text-align:right;">{{ (((encargo_total_grupo_hora['Grupo A']/100) * (encargo_total_grupo_hora['Grupo B']/100)) * 100).toFixed(3) | grana }} %</th>-->
                    <th class="amarelo" style="text-align:right;">{{ (((encargo_total_grupo['Grupo A']/100) * (encargo_total_grupo['Grupo B']/100)) * 100).toFixed(3) | grana }} %</th>
                </tr>
            </tfoot>
        </table>

        <b-modal id="modal-encargo" size="lg" hide-footer title="Adicionar encargo">
            <table class="table">
                <thead>
                <tr>
                    <th>Grupo</th>
                    <th>Encargo</th>
                    <!--<th>Perc. Horista</th>-->
                    <th>Perc. Mensalista</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                <td data-title="Grupo">
                    <b-form-select size="sm" v-model="eitGroup" :options="grupos"></b-form-select>
                </td>
                <td data-title="Encargo">
                    <b-form-input v-model="eitName"></b-form-input>
                </td>
                <!--<td>
                    <vue-autonumeric :options="perc" class="form-control" v-model="eitValorH"></vue-autonumeric>
                </td>-->
                <td data-title="Perc. Mensal">
                    <vue-autonumeric :options="perc" class="form-control" v-model="eitValorM"></vue-autonumeric>
                </td>
                <td data-title=""><button type="button" class="btn btn-success" @click="inserirEnc()">Adicionar</button></td>
                </tr>
                </tbody>
            </table>
        </b-modal>

        
          </b-card>
          <b-modal size="lg" id="ajuda" hide-footer title="Encargos Trabalhistas">
      <div>
      <b-card no-body>
        <b-tabs card>
          <b-tab no-body title="Tutorial" active>
            <b-card-text>
              <b-embed
              type="iframe"
              aspect="16by9"
              src="https://www.youtube.com/embed/SLce7PnCNN8?rel=0"
              allowfullscreen
            ></b-embed>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
      </div>
    </b-modal>
        </div></div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import VueAutonumeric from 'vue-autonumeric'
var vm
export default {
  data () {
    return {
      grupoAtual: '',
      optionsEncargos: [],
      optionsEncargosList: [],
      produtoItem: [],
      encargoItem: [],
      sessao: '',
      loading: false,
      enc: [],
      p: [],
      eitName: '',
      eitGroup: 'Grupo A',
      eitValorH: 0,
      eitValorM: 0,
      eitActive: 1,
      dinheiro: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: this.$session.get('regras')[0].grp_moeda + ' ',
        currencySymbolPlacement: 'p',
        decimalPlaces: 2
      },
      dinheiro2: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: this.$session.get('regras')[0].grp_moeda + ' ',
        currencySymbolPlacement: 'p',
        decimalPlaces: 3
      },
      perc: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: ' %',
        currencySymbolPlacement: 's',
        decimalPlaces: 2
      },
      decimal: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: '',
        decimalPlaces: 2
      },
      novoEncargo: '',
      encargo: 1,
      encargo_total: 0,
      encargo_total_hora: 0,
      encargo_total_grupo: [],
      encargo_total_grupo_hora: [],
      encdiv: [],
      enc_total_hora: [],
      enc_total_mes: [],
      enc_total_parcial: [],
      grupos: [
        {value: 'Grupo A', text: 'Grupo A'},
        {value: 'Grupo B', text: 'Grupo B'},
        {value: 'Grupo C', text: 'Grupo C'}
      ],
      encSelect: []
    }
  },
  beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$router.push('/login')
    }
  },
  created: function () {
    // this.produto = this.$route.params.id
    this.loading = true
    this.verificaSessao()
  },
  filters: {
    grana: function (num) {
      if (typeof num !== 'undefined') {
        return String(parseFloat(num).toFixed(2)).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
      }
    },
    somarArray: function (value) {
      var ret = 0
      for (var i = 0; i < value.length; i++) {
        ret = ret + parseFloat(value[i])
      }
      return ret
    }
  },
  methods: {
    verificaSessao: function () {
      if(isNaN(this.encargo) || this.encargo === 0) {
        this.$notify({
          type: 'danger',
          title: 'Falha ao encontrar produto',
          text: 'Inválido ou não encontrado'
        })
        return false
      }
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        if (!this.permissao('CONFIGURACOES_ENCARGOS'))
        {
          this.$notify({
            type: 'danger',
            title: 'Sem permissão',
            text: 'Você não possui permissão para acessar esta área.'
          })
          return false
        }
        this.campo('pro_Encargo', 'enc_ID', '(enc_active = 1)').then(
          (en) => {
            this.encargo = en
            // Verifica se tem itens de encargo
            this.listar('pro_Encargo_Item', 'eit_ID', '(enc_ID = ' + en + ')').then(
              (r) => {
                if (r.length > 0) {
                  this.recarregar()
                } else {
                  // Recria itens
                  this.remover('pro_Encargo', '(enc_ID > 0)').then(
                    (rem) => {
                      this.verificaSessao()
                    },
                    (rem) => {
                      this.verificaSessao()
                    }
                  )
                }
              },
              (r) => {
                // Recria itens
                this.remover('pro_Encargo', '(enc_ID > 0)').then(
                  (rem) => {
                    this.verificaSessao()
                  },
                  (rem) => {
                    this.verificaSessao()
                  }
                )
              }
            )
          },
          (en) => {
            this.$swal('Criando grupo de encargos...', 'Está faltando definir os grupos e as tabelas de encargos na base desta empresa. O sistema está criando a estrutura agora. Aguarde alguns instantes e, em seguida, selecione o regime tributário de sua empresa.', 'warning')
            this.loading = false
            // Cria os registros padrao
            var w = '?filter=' + encodeURIComponent('grp_ID IS NULL')
            var ordby = '&order=' + encodeURI('enc_ID')
            this.$http.get(this.URLApi + 'prolucro/_table/pro_Encargo' + w + ordby, {
              headers: {
                'X-DreamFactory-Session-Token': this.$session.get('jwt'),
                'X-Dreamfactory-API-Key': this.ChaveApi,
                'Content-Type': 'application/json'
              }
            }).then((response) => {
              var r = response.body.resource
              if (!r) { r = response.body }
              var p = []
              for (var i = 0; i < r.length; i++) {
                p.push(this.inserir('pro_Encargo',{resource: [ {enc_name: r[i].enc_name, enc_active: r[i].enc_active} ]}))
              }
              this.loading = true
              Promise.all(p).then(
                (k) => {
                  var kreal = [0, k[0][Object.keys(k[0])[0]], k[1][Object.keys(k[1])[0]], k[2][Object.keys(k[2])[0]], k[3][Object.keys(k[3])[0]]]
                  // Agora, insere os itens padrão
                  w = '?filter=' + encodeURIComponent('grp_ID IS NULL')
                  ordby = '&order=' + encodeURI('eit_ID')
                  this.$http.get(this.URLApi + 'prolucro/_table/pro_Encargo_Item' + w + ordby, {
                    headers: {
                      'X-DreamFactory-Session-Token': this.$session.get('jwt'),
                      'X-Dreamfactory-API-Key': this.ChaveApi,
                      'Content-Type': 'application/json'
                    }
                  }).then((response2) => {
                    var r2 = response2.body.resource
                    if (!r2) { r2 = response2.body }
                    var p2 = []
                    for (var i = 0; i < r2.length; i++) {
                      p2.push(this.inserir('pro_Encargo_Item',
                        {resource: [{
                          enc_ID: kreal[r2[i].enc_ID], 
                          eit_group: r2[i].eit_group,
                          eit_ordem: r2[i].eit_ordem,
                          eit_name: r2[i].eit_name,
                          eit_valor_hora: r2[i].eit_valor_hora,
                          eit_valor_mes: r2[i].eit_valor_mes,
                          eit_active: r2[i].eit_active
                        }]}))
                    }
                    Promise.all(p2).then(
                      (k2) => {
                        this.$notify({
                          type: 'success',
                          title: 'Registros padrão criados',
                          text: 'Encargos pré-alimentados.'
                        })
                        this.loading = false
                        this.recarregar()
                      },
                      (k2) => {
                        this.loading = false
                      }
                    )
                  }, (response2) => {})
                },
                (k) => {
                  this.loading = false
                }
              )
            }, (response) => {})
          }
        )
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'danger',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    removeEsse: function (index) {
      if (confirm('Tem certeza que deseja remover esse item?')) {
        this.enc[index].eit_valor_mes = 0
        this.enc[index].eit_active = 0
        this.remover('pro_Encargo_Item', 'eit_ID = ' + this.enc[index].eit_ID, '').then(
          (a) => {
            this.recarregar()
          }
        )
      }
    },
    recarregar: function () {
      this.remover('pro_Encargo_Item', 'eit_valor_mes = 0', '')
      this.listar('filtro_Encargo_Dup', 'eit_ID', '').then(
        (dd) => {
          for (var i = 0; i < dd.length; i++) {
            this.remover('pro_Encargo_Item', 'eit_ID = ' + dd[i].eit_ID, '')
          }
        },
        (dd) => {}
      )
      this.listar('pro_Encargo_Item', 'eit_group, eit_name', 'enc_ID = ' + this.encargo + '').then(
        (rtmp) => {
            this.enc = rtmp
            this.encargo_total = 0
            this.encargo_total_hora = 0
            this.encargo_total_grupo = {'Grupo A' : 0, 'Grupo B' : 0, 'Grupo C' : 0}
            this.encargo_total_grupo_hora = {'Grupo A' : 0, 'Grupo B' : 0, 'Grupo C' : 0}
            for (var i = 0; i < rtmp.length; i++) {
                if (rtmp[i].eit_active) {
                  this.encargo_total += rtmp[i].eit_valor_mes
                  this.encargo_total_hora += rtmp[i].eit_valor_hora
                  this.encargo_total_grupo[rtmp[i].eit_group] += rtmp[i].eit_valor_mes
                  this.encargo_total_grupo_hora[rtmp[i].eit_group] += rtmp[i].eit_valor_hora
                }
            }
            this.listar('pro_Encargo', 'enc_name', '').then(
              (t) => {
                this.encSelect = []
                for (var i = 0; i < t.length; i++) {
                  this.encSelect.push({ value: t[i].enc_ID, text: t[i].enc_name})
                }
                this.listar('pro_Encargo', 'enc_name', 'enc_ID = ' + this.encargo).then(
                    (r) => {
                        this.p = r[0]
                        this.atualizar('pro_Encargo', {resource: [ { enc_total_mes : this.encargo_total, enc_total_hora : this.encargo_total_hora } ]}, 'enc_ID = ' + this.encargo, '', '1').then(
                          (r2) => {
                            this.loading = false
                          },
                          (r2) => {
                            this.loading = false
                          }
                        )
                    },
                    (r) => {
                        this.$notify({
                            type: 'danger',
                            title: 'Dados insuficientes',
                            text: 'Preencha as tabelas anteriores antes de acessar esta área.'
                        })
                        this.loading = false
                    }
                )
              },
              (t) => {
                this.$notify({
                    type: 'danger',
                    title: 'Erro de dados insuficientes',
                    text: 'Preencha as tabelas anteriores antes de acessar esta área.'
                })
                this.loading = false
              }
            )
        },
        (rtmp) => {
            this.$notify({
                type: 'danger',
                title: 'Dados insuficientes',
                text: 'Preencha as tabelas anteriores antes de acessar esta área.'
            })
            this.loading = false
        }
      )
    },
    inserirEnc: function () {
      this.loading = true
      // Verifica se já existe
      if (parseFloat(this.eitValorM) === 0.0) {
        // Se existe, avisa
        this.$swal('O percentual precisa ser maior do que zero')
        this.loading = false
        return false
      }
      this.campo('pro_Encargo_Item', 'eit_ID', '(eit_name = ' + this.eitName + ') AND (eit_group = ' + this.eitGroup + ')').then(
        (response) => {
          // Se existe, avisa
          this.$swal('Esse item já existe')
          this.loading = false
          return false
        }, 
        (response) => {
          var elem = {
            enc_ID: this.encargo, 
            eit_group: this.eitGroup,
            eit_name: this.eitName,
            eit_valor_hora: this.eitValorH, 
            eit_valor_mes: this.eitValorM, 
            eit_active: 1,
            grp_ID: this.$session.get('grupo')
          }
          this.criar(elem)
        }
      )
    },
    criar: function (el) {
      this.$http.post(this.URLApi + 'prolucro/_table/pro_Encargo_Item', {resource: [ el ]}, {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then(
        (response) => {
          this.eitName = ''
          this.eitValorH = 0
          this.eitValorM = 0
          this.enc = []
          this.recarregar()
        },
        (response) => {
          this.$swal('Falha ao inserir')
        }
      )
    },
    editar: function (index) {
      this.loading = true
      this.atualizar('pro_Encargo_Item', {enc_ID: this.encargo, eit_valor_hora: this.enc[index].eit_valor_hora, eit_valor_mes: this.enc[index].eit_valor_mes, 
      eit_active: this.enc[index].eit_active, eit_name: this.enc[index].eit_name, eit_group: this.enc[index].eit_group}, this.enc[index].eit_ID, '', '1').then(
        (response) => {
          console.log(response)
          this.recarregar()
        },
        (response) => {
          this.$swal('Erro ao atualizar: ' + JSON.stringify(response))
          this.loading = false
        }
      )
    },
    mudarEncargo: function () {
      this.loading = true
      this.atualizar('pro_Encargo', {resource: [ {enc_active: 0} ]}, '(enc_ID > 0)' + (this.$session.get('grupo') ? ' AND (grp_ID = ' + this.$session.get('grupo') + ')' : ''), '', '1').then(
        (r) => {
          this.atualizar('pro_Encargo', {resource: [ {enc_active: 1} ]}, 'enc_ID = ' + this.encargo, '', '1').then(
            (r2) => {
              this.recarregar()
            },
            (r2) => {
              this.$swal('Erro ao atualizar: ' + JSON.stringify(r2))
              this.loading = false
            }
          )
        },
        (r) => {
          this.$swal('Erro ao atualizar: ' + JSON.stringify(r))
          this.loading = false
        }
      )
    },
    ajuda: function() {
      this.$bvModal.show('ajuda')
    }
  },
  components: {
    Loading,
    VueAutonumeric
  }
}
</script>